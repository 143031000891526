import { useGLTF, Environment } from '@react-three/drei';

const IronMan = () => {
    const ironManModel = useGLTF('./IronMan.glb');
    return(
        <>
            <ambientLight />
            <Environment files="./img/workshop_1k.hdr" background />
            <mesh>
                <primitive
                    object={ironManModel.scene}
                    rotation={[0, -5, 0]}
                    position={[0, -1.2, 0]}
                    scale={1.2}
                />
            </mesh>
        </>
    )
}
export default IronMan;
