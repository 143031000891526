import React from 'react'
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { getUser, logout } from './utils/helpers';

function Navigation({history}) {
  return (
    <div>
      <nav className="border split-nav">
          <div className="nav-brand">
            <h3><Link to="/">Daytrash</Link></h3>
          </div>
          <div className="collapsible">
            <input id="collapsible1" type="checkbox" name="collapsible1" />
            <label htmlFor="collapsible1">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </label>
            <div className="collapsible-body">
              <ul className="inline">
                
                {
                  getUser() && (
                    getUser() && (<li><Link to="electronics">Elektronik</Link></li>)
                  )
                }
                
                {
                  getUser() && (
                    getUser() && (<li><Link to="programming">Programmierung</Link></li>)
                  )
                }

                {
                  !getUser() && (<li><Link to="login">Login</Link></li>)
                }

                {
                  getUser() && (
                    <li onClick={() => logout(() => history.push('/login'))} style={{cursor: 'pointer'}}>Logout</li>
                  )
                }
                
                {/*<li><a href="#">Github</a></li>*/}
              </ul>
            </div>
          </div>
        </nav>
    </div>
  )
}

export default withRouter(Navigation);
