import "./Impressum.css";
import React from "react";

function Impressum() {
  return (
    <div>
      <div className="contractDetails">
        <h1 class="DieterHeading1">Impressum</h1>
        <h2 class="HeadingLeft">
          <span>Anbieter</span>
        </h2>
        <p class="Body">Maik Tappe</p>
        <p class="Body">
          <span class="multiline">none</span>
        </p>
        <h2 class="HeadingLeft">
          <span>Kontakt</span>
        </h2>
        <p class="Body">E-Mail: maik.tappe82@googlemail.com</p>
        <p class="Body">Telefon: 02368 8989109</p>
        <h2 class="HeadingLeft">
          <span>Angaben zu audiovisuellen Mediendiensten </span>
        </h2>
        <p class="Body">Sitzland: Deutschland </p>
        <p class="Body">
          Regulierungs- und Aufsichtsbehörde: Landesanstalt für Medien
          Nordrhein-Westfalen (LfM NRW){" "}
        </p>
        <h2 class="HeadingLeft">
          <span>Haftungsausschluss</span>
        </h2>
        <p class="Body">
          Wir sind für die Inhalte unserer Internetseiten nach den Maßgaben der
          allgemeinen Gesetzen, insbesondere nach § 7 Abs. 1 des
          Telemediengesetzes, verantwortlich. Alle Inhalte werden mit der
          gebotenen Sorgfalt und nach bestem Wissen erstellt. Soweit wir auf
          unseren Internetseiten mittels Hyperlink auf Internetseiten Dritter
          verweisen, können wir keine Gewähr für die fortwährende Aktualität,
          Richtigkeit und Vollständigkeit der verlinkten Inhalte übernehmen, da
          diese Inhalte außerhalb unseres Verantwortungsbereichs liegen und wir
          auf die zukünftige Gestaltung keinen Einfluss haben. Sollten aus Ihrer
          Sicht Inhalte gegen geltendes Recht verstoßen oder unangemessen sein,
          teilen Sie uns dies bitte mit.
        </p>
        <p class="Body">
          Die rechtlichen Hinweise auf dieser Seite sowie alle Fragen und
          Streitigkeiten im Zusammenhang mit der Gestaltung dieser Internetseite
          unterliegen dem Recht der Bundesrepublik Deutschland.
        </p>
        <h2 class="HeadingLeft">
          <span>Datenschutzhinweis</span>
        </h2>
        <p class="Body">
          Unsere Datenschutzerklärung finden Sie unter:
          www.daytrash.de/datenschutz
        </p>
        <h2 class="HeadingLeft">
          <span>Urheberrechtshinweis</span>
        </h2>
        <p class="Body">
          Die auf unserer Internetseite vorhandenen Texte, Bilder, Fotos, Videos
          oder Grafiken unterliegen in der Regel dem Schutz des Urheberrechts.
          Jede unberechtigte Verwendung (insbesondere die Vervielfältigung,
          Bearbeitung oder Verbreitung) dieser urheberrechtsgeschützten Inhalte
          ist daher untersagt. Wenn Sie beabsichtigen, diese Inhalte oder Teile
          davon zu verwenden, kontaktieren Sie uns bitte im Voraus unter den
          oben stehenden Angaben. Soweit wir nicht selbst Inhaber der benötigten
          urheberrechtlichen Nutzungsrechte sein sollten, bemühen wir uns, einen
          Kontakt zum Berechtigten zu vermitteln.
        </p>
        <p class="DieterSmall">
          <span>Erstellt mit freundlicher Unterstützung von </span>
          <a href="https://www.dieter-datenschutz.de/">
            <span>Dieter macht den Datenschutz</span>
          </a>
        </p>
      </div>
    </div>
  );
}

export default Impressum;
