import './Home.css';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

import IronMan from './components/canvas/IronMan';

function Home () {

    const bgColor = ({ gl }) => {
        gl.setClearColor('#ffffff', 1)
    }

    return(
        <Canvas
            id='canvas'
            style={{ position: 'fixed' }}
            camera={{ position: [2, 0, -0.2], near: 0.025 }}
            onCreated={bgColor}>
            <ambientLight />
            <OrbitControls />
            <IronMan />
        </Canvas>
    );
};

export default Home;