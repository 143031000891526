import React from 'react'

function Electronics() {
  return (
    <div>
      <h1 className='bg-primary'>Elektronik</h1>
    </div>
  )
}

export default Electronics;