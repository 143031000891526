import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { authenticate, getUser } from './utils/helpers';

function Login(props) {
    const [state, setState] = useState({
        name: '',
        password: ''
    });
    const { name, password } = state;

    useEffect((props) => {
        getUser() && props.history.push('/');
    }, []);

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.value });
    };

    const handleSubmit = event => {
        event.preventDefault();
        console.table({ name, password });
        axios
            .post('https://backend.daytrash.de/api/login', { name, password })
            .then(response => {
                console.log(response.data);
                authenticate(response, () => props.history.push('/'));
            })
            .catch(error => {
                if(error.response.data.error !== 'undefined') {
                    console.log(error.response);
                    alert(error.response.data.error);
                }
            });
    };

  return (
      <section className="vh-100 gradient-custom">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div className="card bg-dark text-white">
                    <div className="card-body p-5 text-center">

                        <div className="mb-md-5 mt-md-4 pb-5">

                        <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                        <p className="text-white-50 mb-5">Please enter your login and password!</p>

                        <div data-mdb-input-init className="form-outline form-white mb-4">
                            <input onChange={handleChange('name')} value={name} type="email" id="typeEmailX" className="form-control form-control-lg" required/>
                            <label className="form-label" htmlFor="typeEmailX">Email</label>
                        </div>

                        <div data-mdb-input-init className="form-outline form-white mb-4">
                            <input onChange={handleChange('password')} value={password} type="password" id="typePasswordX" className="form-control form-control-lg" required/>
                            <label className="form-label" htmlFor="typePasswordX">Password</label>
                        </div>

                        <button data-mdb-button-init data-mdb-ripple-init onClick={handleSubmit} className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
  )
}

export default withRouter(Login);
