import React from "react";
import './Dsgvo.css';

function Dsgvo() {
  return (
    <div>
      <div class="contractDetails">
        <h1 class="DieterHeading1">Datenschutzerklärung </h1>
        <h2 class="DieterHeading2">
          <span class="number-prefix-p">1. </span>
          <span>Einleitung</span>
        </h2>
        <p class="Body">Diese Website wird betrieben von: Maik Tappe.</p>
        <p class="Body">
          Es ist uns sehr wichtig, mit den Daten unserer Website-Besucher
          vertrauensvoll umzugehen und sie bestmöglich zu schützen. Aus diesem
          Grund leisten wir alle Anstrengungen, um die Anforderungen der DSGVO
          zu erfüllen.
        </p>
        <p class="Body">
          Im Folgenden erläutern wir Ihnen, wie wir Ihre Daten auf unserer
          Webseite verarbeiten. Dazu verwenden wir eine möglichst klare und
          transparente Sprache, damit Sie wirklich verstehen, was mit Ihren
          Daten passiert.
        </p>
        <h2 class="DieterHeading2">
          <span class="number-prefix-p">2. </span>
          <span>Allgemeine Informationen</span>
        </h2>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.1 </span>
          <span>
            Verarbeitung von personenbezogenen Daten und andere Begriffe
          </span>
        </h3>
        <p class="Body">
          Datenschutz gilt bei der Verarbeitung von personenbezogenen Daten.
          Personenbezogen meint alle Daten, mit denen Sie persönlich
          identifiziert werden können. Das ist z.B. die IP-Adresse des Geräts
          (PC, Laptop, Smartphone, etc.) vor dem Sie gerade sitzen. Verarbeitet
          werden solche Daten dann, wenn ‚irgendetwas damit passiert‘. Hier wird
          z.B. die IP vom Browser an unseren Provider übermittelt und dort
          automatisch gespeichert. Das ist dann eine Verarbeitung (gemäß Art. 4
          Nr. 2 DSGVO) von personenbezogenen Daten (nach Art. 4 Nr. 1 DSGVO).
        </p>
        <p class="Body">
          Diese und weitere gesetzlichen Definitionen sind in Art. 4 DSGVO zu
          finden.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.2 </span>
          <span>
            Anzuwendende Vorschriften/ Gesetze – DSGVO, BDSG und TTDSG
          </span>
        </h3>
        <p class="Body">
          Der Umfang des Datenschutzes wird durch Gesetze geregelt. Das sind in
          diesem Fall die DSGVO (Datenschutzgrundverordnung) als europäische
          Verordnung und das BDSG (Bundesdatenschutzgesetz) als nationales
          Gesetz.
        </p>
        <p class="Body">
          Außerdem ergänzt das TTDSG die Vorschriften aus der DSGVO, soweit es
          sich um den Einsatz von Cookies handelt.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.3 </span>
          <span>Der Verantwortliche</span>
        </h3>
        <p class="Body">
          Verantwortlich für die Datenverarbeitung auf dieser Website ist der
          Verantwortliche im Sinne der DSGVO. Das ist diejenige natürliche oder
          juristische Person, die allein oder gemeinsam mit anderen über die
          Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
          entscheidet.
        </p>
        <p class="Body">Den Verantwortlichen erreichen Sie unter:</p>
        <p class="Body">Maik Tappe</p>
        <p class="Body">
          <span class="multiline">none</span>
        </p>
        <p class="Body">maik.tappe82@googlemail.com</p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.4 </span>
          <span>
            So werden Daten auf dieser Website grundsätzlich verarbeitet
          </span>
        </h3>
        <p class="Body">
          Wie wir bereits festgestellt haben, gibt es Daten (z.B. IP-Adresse)
          die automatisch erhoben werden. Diese Daten werden überwiegend für die
          technische Bereitstellung der Homepage benötigt. Soweit wir darüber
          hinaus personenbezogene Daten verwenden oder andere Daten erheben,
          klären wir Sie darüber auf bzw. fragen nach einer Einwilligung.
        </p>
        <p class="Body">
          Andere personenbezogene Daten teilen Sie uns bewusst mit.{" "}
        </p>
        <p class="Body">
          Detaillierte Informationen hierzu erhalten Sie weiter unten.{" "}
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.5 </span>
          <span>Ihre</span>
          <span> Rechte</span>
        </h3>
        <p class="Body">
          Die DSGVO stattet Sie mit umfassenden Rechten aus. Diese sind z.B. die
          unentgeltliche Auskunft über Herkunft, Empfänger und Zweck Ihrer
          gespeicherten personenbezogenen Daten. Außerdem können Sie die
          Berichtigung, Sperrung oder Löschung dieser Daten verlangen oder sich
          bei der zuständigen Datenschutzaufsichtsbehörde beschweren. Eine
          erteilte Einwilligung können Sie jederzeit widerrufen.
        </p>
        <p class="Body">
          Wie diese Rechte im Detail aussehen und wie sie wahrzunehmen sind,
          finden Sie im letzten Abschnitt dieser Datenschutzerklärung.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.6 </span>
          <span>Datenschutz – Unsere Sicht</span>
        </h3>
        <p class="Body">
          Datenschutz ist für uns mehr als nur eine lästige Pflicht!
          Personenbezogene Daten haben einen großen Wert und ein achtsamer
          Umgang mit diesen Daten sollte in unserer digitalisierten Welt
          selbstverständlich sein. Außerdem sollen Sie als Website-Besucher in
          der Lage sein, selbst zu entscheiden, was, wann und durch wen mit
          Ihren Daten „passiert“. Deswegen verpflichten wir uns, alle
          gesetzlichen Bestimmungen einzuhalten, erheben nur die für uns
          notwendigen Daten und behandeln diese selbstverständlich vertraulich.{" "}
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.7 </span>
          <span>Weitergabe und Löschung</span>
        </h3>
        <p class="Body">
          Die Weitergabe und Löschung von Daten sind ebenfalls wichtige und
          sensible Themen. Deswegen möchten wir Sie vorab kurz über unsere
          generelle Herangehensweise hierzu informieren.
        </p>
        <p class="Body">
          Eine Weitergabe der Daten findet nur aufgrund einer Rechtsgrundlage
          und nur dann statt, wenn dies unumgänglich ist. Dies kann insbesondere
          dann der Fall sein, wenn es sich um einen sogenannten
          Auftragsverarbeiter handelt und ein Auftragsverarbeitungsvertrag nach
          Art. 28 DSGVO geschlossen wurde.
        </p>
        <p class="Body">
          Wir löschen Ihre Daten dann, wenn der Zweck und die Rechtsgrundlage
          zur Verarbeitung wegfallen und der Löschung zudem keine anderen
          gesetzlichen Verpflichtungen entgegenstehen. Einen ‚guten‘ Überblick
          darüber gibt auch Art. 17 DSGVO.
        </p>
        <p class="Body">
          Alle weiterführenden Informationen entnehmen Sie bitte dieser
          Datenschutzerklärung und wenden sich bei konkreten Fragen an den
          Verantwortlichen.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.8 </span>
          <span>Hosting </span>
        </h3>
        <p class="Body">
          Diese Website wird extern gehostet. Die personenbezogenen Daten, die
          auf dieser Website erfasst werden, werden auf den Servern des Hosters
          gespeichert. Hierbei handelt es sich zum einen um die automatisch
          erhobenen und gespeicherten Logfiles (näheres dazu siehe unten), wie
          auch um alle sonstigen Daten, die die Website Besucher angeben.{" "}
        </p>
        <p class="Body">
          Das externe Hosting erfolgt zum Zwecke einer sicheren, schnelle und
          zuverlässigen Bereitstellung unserer Website und dient in diesem
          Zusammenhang der Vertragserfüllung gegenüber unseren potentiellen und
          bestehenden Kunden.
        </p>
        <p class="Body">
          Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a, b und f
          DSGVO, sowie §&nbsp;25 Abs. 1 TTDSG, soweit eine Einwilligung die
          Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
          des Website Besuchers oder Users im Sinne des TTDSG umfasst.
        </p>
        <p class="Body">
          Unser Hoster verarbeitet nur solche Daten, die zur Erfüllung seiner
          Leistungspflicht erforderlich sind und agiert als unser
          Auftragsverarbeiter, das heißt, er unterliegt unseren Weisungen. Wir
          haben einen entsprechenden Vertrag über die Auftragsverarbeitung mit
          unserem Hoster geschlossen.
        </p>
        <p class="Body">Folgenden Hoster setzen wir ein:</p>
        <p class="Body">Strato</p>
        <p class="Body">Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin. </p>
        <p class="Body">
          <a href="mailto:impressum@strato.de">
            <span>impressum@strato.de</span>
          </a>
        </p>
        <p class="Body">
          <a href="https://www.strato.de/datenschutz/">
            <span>https://www.strato.de/datenschutz/</span>
          </a>
          <span>.</span>
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">2.9 </span>
          <span>Rechtsgrundlagen</span>
        </h3>
        <p class="Body">
          Die Verarbeitung von personenbezogenen Daten benötigt immer eine
          Rechtsgrundlage. Die DSGVO sieht in Art. 6 Abs. 1 Satz 1 folgende
          Möglichkeiten vor:
        </p>
        <p class="DieterLista">
          <span class="number-prefix-p">a) </span>
          <span>
            Die betroffene Person hat ihre Einwilligung zu der Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen oder mehrere
            bestimmte Zwecke gegeben;
          </span>
        </p>
        <p class="DieterLista">
          <span class="number-prefix-p">b) </span>
          <span>
            die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen;
          </span>
        </p>
        <p class="DieterLista">
          <span class="number-prefix-p">c) </span>
          <span>
            die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich, der{" "}
          </span>
          <span>der Verantwortliche</span>
          <span>unterliegt;</span>
        </p>
        <p class="DieterLista">
          <span class="number-prefix-p">d) </span>
          <span>
            die Verarbeitung ist erforderlich, um lebenswichtige Interessen der
            betroffenen Person oder einer anderen natürlichen Person zu
            schützen;
          </span>
        </p>
        <p class="DieterLista">
          <span class="number-prefix-p">e) </span>
          <span>
            die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich,
            die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
            Gewalt erfolgt, die{" "}
          </span>
          <span>dem Verantwortlichen</span>
          <span> übertragen wurde;</span>
        </p>
        <p class="DieterLista">
          <span class="number-prefix-p">f) </span>
          <span>
            die Verarbeitung ist zur Wahrung der berechtigten Interessen{" "}
          </span>
          <span>des Verantwortlichen</span>
          <span> oder </span>
          <span>eines Dritten</span>
          <span>
            {" "}
            erforderlich, sofern nicht die Interessen oder Grundrechte und
            Grundfreiheiten der betroffenen Person, die den Schutz
            personenbezogener Daten erfordern, überwiegen, insbesondere dann,
            wenn es sich bei der betroffenen Person um ein Kind handelt.
          </span>
        </p>
        <p class="Body">
          In den folgenden Abschnitten werden wir Ihnen die konkrete
          Rechtsgrundlage zu der jeweiligen Verarbeitung nennen.
        </p>
        <h2 class="DieterHeading2">
          <span class="number-prefix-p">3. </span>
          <span>Das passiert auf unserer Website </span>
        </h2>
        <p class="Body">
          Durch den Besuch auf unserer Website verarbeiten wir personenbezogene
          Daten von Ihnen.
        </p>
        <p class="Body">
          Um diese Daten bestmöglich gegen einen unbefugten Eingriff durch
          Dritte zu schützen, benutzen wir eine SSL- bzw. TLS-Verschlüsselung.
          Diese verschlüsselte Verbindung erkennen Sie daran, dass in der
          Adresszeile Ihres Browsers ein https:// oder ein Schloss-Symbol
          abgebildet ist.
        </p>
        <p class="Body">
          Im Folgenden erfahren Sie, welche Daten beim Besuch unserer Website
          erhoben werden, zu welchem Zweck dies geschieht und aufgrund welcher
          Rechtsgrundlage.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">3.1 </span>
          <span>Datenerhebung beim Aufruf der Website</span>
        </h3>
        <p class="Body">
          Durch das Aufrufen der Website werden automatisch Informationen in
          sogenannten Server-Log-Dateien gespeichert. Es handelt sich um
          folgende Informationen:
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Browsertyp und Browserversion</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>verwendetes Betriebssystem</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Referrer URL</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Hostname des zugreifenden Rechners</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Uhrzeit der Serveranfrage</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>IP-Adresse</span>
        </p>
        <p class="Body">
          Diese Daten werden vorübergehend benötigt, um Ihnen unsere Website
          dauerhaft und problemlos anzeigen zu können. Insbesondere dienen diese
          Daten damit folgenden Zwecken:
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Systemsicherheit der Website</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Systemstabilität der Website</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Fehlerbehebung auf der Website</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Verbindungsaufbau zur Website</span>
        </p>
        <p class="BulletList1">
          <span class="number-prefix-p">• </span>
          <span>Darstellung der Website</span>
        </p>
        <p class="Body">
          Die Datenverarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO und
          erfolgt aus unserem berechtigten Interesse an der Verarbeitung dieser
          Daten, insbesondere aus dem Interesse an der Funktionalität der
          Website sowie deren Sicherheit.
        </p>
        <p class="Body">
          Diese Daten werden nach Möglichkeit pseudonymisiert gespeichert und
          nach Erreichen des jeweiligen Zwecks gelöscht.{" "}
        </p>
        <p class="Body">
          Soweit die Server-Log-Dateien die Identifizierung der betroffenen
          Person ermöglicht, werden die Daten maximal für einen Zeitraum von 14
          Tagen gespeichert. Eine Ausnahme besteht dann, wenn ein
          sicherheitsrelevantes Ereignis auftritt. In diesem Fall werden die
          Server-Log-Dateien bis zur Beseitigung und abschließenden Aufklärung
          des sicherheitsrelevanten Ereignisses gespeichert.{" "}
        </p>
        <p class="Body">
          Im Übrigen findet eine Zusammenführung mit anderen Daten findet nicht
          statt.{" "}
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">3.2 </span>
          <span>Cookies</span>
        </h3>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.2.1 </span>
          <span>Allgemeines</span>
        </h4>
        <p class="Body">
          Diese Website nutzt sogenannte Cookies. Dabei handelt es sich um einen
          Datensatz, eine Information, die im Browser Ihres Endgeräts
          gespeichert wird und in einer Relation zu unserer Website steht.{" "}
        </p>
        <p class="Body">
          Durch das Setzen von Cookies kann dem Besucher insbesondere die
          Navigation der Website erleichtert werden.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.2.2 </span>
          <span>Ablehnen von Cookies</span>
        </h4>
        <p class="Body">
          Das Setzen von Cookies kann verhindert werden, indem Sie die
          Einstellungen Ihres Browsers anpassen.
        </p>
        <p class="Body">
          Hier finden Sie die entsprechenden Verlinkungen zu häufig benutzten
          Browsern:
        </p>
        <p class="Body">
          <span>Mozilla Firefox: </span>
          <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?redirectslug=Cookies+l%C3%B6schen&amp;redirectlocale=de">
            <span>
              https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?redirectslug=Cookies+l%C3%B6schen&amp;redirectlocale=de
            </span>
          </a>
        </p>
        <p class="Body">
          <span>Google Chrome: </span>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=de">
            <span>
              https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=de
            </span>
          </a>
        </p>
        <p class="Body">
          <span>Microsoft Edge: </span>
          <a href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
            <span>
              https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d
            </span>
          </a>
        </p>
        <p class="Body">
          <span>Safari: </span>
          <a href="https://support.apple.com/de-de/guide/mdm/mdmf7d5714d4/web">
            <span>
              https://support.apple.com/de-de/guide/mdm/mdmf7d5714d4/web
            </span>
          </a>
          <span> und </span>
          <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
            <span>
              https://support.apple.com/de-de/guide/safari/sfri11471/mac
            </span>
          </a>
          <span>Soweit </span>
          <span>Sie</span>
          <span> einen anderen Browser </span>
          <span>nutzen</span>
          <span>, empfiehlt es sich den Namen </span>
          <span>Ihres</span>
          <span>
            {" "}
            Browsers und ‚Cookies löschen und verwalten‘ in eine Suchmaschine
            einzugeben und dem offiziellen Link zu{" "}
          </span>
          <span>Ihrem</span>
          <span> Browser zu folgen.</span>
        </p>
        <p class="Body">
          <span>Alternativ </span>
          <span>können Sie Ihre</span>
          <span> Cookie-Einstellungen auch unter </span>
          <a href="http://www.aboutads.info/choices/">
            <span>www.aboutads.info/choices/</span>
          </a>
          <span>oder </span>
          <a href="http://www.youronlinechoices.com">
            <span>www.youronlinechoices.com</span>
          </a>
          <span>verwalten.</span>
        </p>
        <p class="Body">
          Wir müssen Sie allerdings darauf hinweisen, dass eine umfassende
          Blockierung/Löschung von Cookies zu Beeinträchtigungen bei der Nutzung
          der Website führen kann.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.2.3 </span>
          <span>Technisch notwendige Cookies</span>
        </h4>
        <p class="Body">
          Wir setzen auf dieser Website technisch notwendige Cookies ein, damit
          unsere Website fehlerfrei und gemäß den geltenden Gesetzen
          funktioniert. Sie helfen dabei, die Website nutzerfreundlich zu
          gestalten. Einige Funktionen unserer Website können ohne den Einsatz
          von Cookies nicht dargestellt werden.
        </p>
        <p class="Body">
          Rechtsgrundlage hierfür ist je nach Einzelfall Art. 6 Abs. 1 lit. b, c
          und/oder f DSGVO.{" "}
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.2.4 </span>
          <span>Technisch nicht notwendige Cookies</span>
        </h4>
        <p class="Body">
          Außerdem verwenden wir auf unserer Website auch solche Cookies, die
          technisch nicht notwendig sind. Diese Cookies dienen unter anderem
          dazu, das Surfverhalten des Website Besuchers zu analysieren oder
          Funktionen der Website anzubieten, die jedoch technisch nicht zwingend
          notwendig sind.
        </p>
        <p class="Body">
          Rechtsgrundlage hierfür ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit.
          a DSGVO.{" "}
        </p>
        <p class="Body">
          Technisch nicht notwendige Cookies werden nur mit Ihrer Einwilligung
          gesetzt, die Sie jederzeit im Cookie-Consent-Tool widerrufen können.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">3.3 </span>
          <span>Datenverarbeitung durch Nutzereingabe</span>
        </h3>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.3.1 </span>
          <span>Fragebögen/Formulare</span>
        </h4>
        <p class="DieterLista">
          <span class="number-prefix-p">a) </span>
          <span>Eigenentwicklung</span>
        </p>
        <p class="Body">
          Wir integrieren selbst entwickelte Formulare auf unserer Website.{" "}
        </p>
        <p class="Body">
          Die eingegebenen Daten werden auf unseren Servern
          gespeichert. &nbsp;&nbsp;
        </p>
        <p class="Body">
          Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a DSGVO
          und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Die
          Rechtmäßigkeit der bereits erfolgten Verarbeitungen bleibt von einem
          ggf. erfolgten Widerruf unberührt. &nbsp;
        </p>
        <p class="Body">
          Die gespeicherten Daten können jederzeit per E-Mail zur Verfügung
          gestellt werden oder es kann eine Aufforderung zur Löschung der Daten
          abgegeben werden.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">3.4 </span>
          <span>Analyse- und Trackingtools</span>
        </h3>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.4.1 </span>
          <span>Google Maps</span>
        </h4>
        <p class="Body">
          Wir nutzen auf dieser Website Google Maps. Google Maps ist ein
          Web-Mapping-Dienst. Dieser Dienst wird angeboten von der Google Irland
          Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
        </p>
        <p class="Body">
          Bei der Nutzung von Google Maps wird die IP-Adresse gespeichert. Diese
          Daten werden in der Regel an einen Server von Google in den USA
          übertragen und gespeichert. Hierauf haben wir keinen Einfluss. Google
          kann hierbei für eine einheitliche Darstellung Google Fonts verwenden.
          Diese Fonts werden im Browsercache des Website-Besuchersdes/der
          Website-Besucher*in geladen.
        </p>
        <p class="Body">
          Google Maps verwendet Cookies. Diese Cookies werden nur mit einer
          entsprechenden Einwilligung gesetzt. Die Einwilligung ist jederzeit
          widerrufen.
        </p>
        <p class="Body">
          Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
          soweit diese Einwilligung den Zugriff auf Informationen im Endgerät
          des Nutzers oder die Speicherung von Cookies im Sinne des TTDSG
          umfasst.{" "}
        </p>
        <p class="Body">
          Bei der Datenübertragung in die USA greifen die
          Standardvertragsklauseln (SCC) der EU-Kommission.{" "}
        </p>
        <p class="Body">Weitere Details:</p>
        <p class="Body">
          <a href="https://business.safety.google/controllerterms/">
            <span>
              https://privacy.google.com/businesses/gdprcontrollerterms/
            </span>
          </a>
          <span> und</span>
        </p>
        <p class="Body">
          <a href="https://business.safety.google/controllerterms/sccs/">
            <span>
              https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
            </span>
          </a>
        </p>
        <p class="Body">
          <a href="https://policies.google.com/privacy?hl=de">
            <span>https://policies.google.com/privacy?hl=de</span>
          </a>
          <span>.</span>
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.4.2 </span>
          <span>YouTube</span>
        </h4>
        <p class="Body">
          Auf dieser Website binden wir YouTube-Videos ein. YouTube ist eine
          Online-Videoplattform. Dieser Dienst wird angeboten von der Google
          Irland Limited („Google“), Gordon House, Barrow Street, Dublin 4,
          Irland.
        </p>
        <p class="Body">
          Sobald Sie auf unserer Website ein Video starten, wird eine Verbindung
          zu den Servern von YouTube hergestellt. YouTube kann nach dem Start
          eines Videos Cookies auf dem Endgerät des Website-Besuchers setzen, um
          Einstellungen und Präferenzen zu speichern und anschließend
          personalisierte Werbung zu schalten. Die hieraus gewonnen
          Informationen werden auch für Videostatistiken, die Verbesserung der
          Anwendungsfreundlichkeit und zur Vorbeugung von Betrugsversuchen
          genutzt.
        </p>
        <p class="Body">
          Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a DSGVO
          und § 25 Abs. 1 TTDSG, soweit diese Einwilligung den Zugriff auf
          Informationen im Endgerät des Nutzers oder die Speicherung von Cookies
          im Sinne des TTDSG umfasst. Diese Einwilligung ist jederzeit
          widerrufbar.
        </p>
        <p class="Body">Weitere Informationen: </p>
        <p class="Body">
          <a href="https://policies.google.com/privacy?hl=de">
            <span>https://policies.google.com/privacy?hl=de</span>
          </a>
          <span>.</span>
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.4.3 </span>
          <span>
            <span>statcounter</span>
          </span>
        </h4>
        <p class="Body">https://statcounter.com/</p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">3.5 </span>
          <span>Drittinhalte</span>
        </h3>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.5.1 </span>
          <span>Google Fonts</span>
        </h4>
        <p class="Body">
          Wir haben Google Fonts lokal auf unserem Server eingebunden. Damit
          werden, trotz der Nutzung, keine Daten an Google übertragen.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.5.2 </span>
          <span>Font Awesome</span>
        </h4>
        <p class="Body">
          Wir haben Adobe Fonts lokal auf unserem Server eingebunden. Damit
          werden, trotz der Nutzung, keine Daten an Adobe übertragen.&nbsp;
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">3.5.3 </span>
          <span>Bootstrap</span>
        </h4>
        <p class="Body">
          Wir verwenden auf unserer Seite den Dienst Bootstrap CDN des
          Unternehmens Prospect One Sp., Krolweska 65A, 30-081 Krakow, Polen.
        </p>
        <p class="Body">
          Es handelt sich bei diesem Dienst um ein Open-Source-Dienst von
          jsdelivr.com, welcher als Content Delivery Network (CDN) regional
          verteilte Server über das Internet miteinader verbindet. Dadurch
          können Inhalte unserer Website schnell und zuverlässig ausgeliefert
          werden, wodurch eine optimale Erreichbarkeit sichergestellt wird.{" "}
        </p>
        <p class="Body">
          Bootrstrap liefert JavaScript-Bibliotheken an den Browser des
          Website-Besuchers aus. Anschließend lädt der Browser Dateien vom
          Bootstrap CDN herunter, wodurch personenbezogene Daten an Bootrstap
          übermittelt werden. Dazu zählt die IP-Adresse, Browsertyp,
          Browserversion, welche Website geladen wird, Uhrzeit und Datum des
          Besuchs.
        </p>
        <p class="Body">
          Soweit wir eine Einwilligung für den Einsatz dieses Dienstes erfragen,
          ist die Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO. Diese Einwilligung
          kann jederzeit widerrufen werden.
        </p>
        <p class="Body">
          Ansonsten ist Rechtsgrundlage für die Verarbeitung der
          personenbezogenen Daten Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein
          berechtigtes Interesse an der Optimierung und Sicherstellung der
          Inhalte auf unserer Website.
        </p>
        <p class="Body">
          Im Falle einer Datenübermittlung in die USA greifen die
          Standardvertragsklauseln (SCC) der EU-Kommission.
        </p>
        <p class="Body">Weitere Informationen:</p>
        <p class="Body">
          <a href="https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net">
            <span>
              https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net
            </span>
          </a>
          <span>.</span>
        </p>
        <h2 class="DieterHeading2">
          <span class="number-prefix-p">4. </span>
          <span>Das ist sonst noch wichtig</span>
        </h2>
        <p class="Body">
          Zum Abschluss möchten wir Sie ausführlich und detailliert über Ihre
          Rechte informieren und Ihnen mitteilen, wie Sie über Veränderungen der
          datenschutzrechtlichen Anforderungen informiert werden wirst.
        </p>
        <h3 class="DieterHeading3">
          <span class="number-prefix-p">4.1 </span>
          <span>Ihre</span>
          <span> Rechte im Detail</span>
        </h3>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.1 </span>
          <span>Auskunftsrecht nach Art. 15 DSGVO</span>
        </h4>
        <p class="Body">
          Sie können Auskunft darüber verlangen, ob personenbezogenen Daten von
          Ihnen verarbeitet werden. Ist das der Fall, können Sie weitere
          Auskünfte zur Art und Weise der Verarbeitung einfordern. Eine
          detaillierte Aufzählung finden Sie in Art. 15 Abs. 1 lit. a bis h
          DSGVO.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.2 </span>
          <span>Recht auf Berichtigung nach Art. 16 DSGVO</span>
        </h4>
        <p class="Body">
          Dieses Recht umfasst die Berichtigung unrichtiger Daten und die
          Ergänzung unvollständiger personenbezogener Daten.{" "}
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.3 </span>
          <span>Recht auf Löschung nach Art. 17 DSGVO</span>
        </h4>
        <p class="Body">
          Dieses sogenannte ‚Recht auf Vergessenwerden‘ gibt Ihnen das Recht,
          unter bestimmten Voraussetzungen, die Löschung der personenbezogenen
          Daten durch den Verantwortlichen zu verlangen. Dies ist grundsätzlich
          dann der Fall, wenn der Zweck der Datenverarbeitung entfallen ist,
          wenn eine Einwilligung widerrufen wurde oder die Ausgangsverarbeitung
          ohne Rechtsgrundlage stattfand. Eine detaillierte Aufzählung von
          Gründen finden Sie in Art. 17 Abs. 1 lit. a bis f DSGVO. Dieses „Recht
          auf Vergessenwerden“ korrespondiert darüber hinaus mit der Pflicht des
          Verantwortlichen aus Art. 17 Abs. 2 DSGVO angemessene Maßnahmen zu
          ergreifen, um eine allgemeine Löschung der Daten herbeizuführen.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.4 </span>
          <span>
            Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO
          </span>
        </h4>
        <p class="Body">
          Dieses Recht ist an die Voraussetzungen gemäß Art. 18 Abs. 1 lit. a
          bis d geknüpft.{" "}
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.5 </span>
          <span>Recht auf Datenübertragbarkeit nach Art. 20 DSGVO</span>
        </h4>
        <p class="Body">
          Hier wird das grundsätzliche Recht zum Erhalt der eigenen Daten in
          einer gängigen Form und der Übermittlung an einen anderen
          Verantwortlichen geregelt. Dies gilt allerdings nur für die Daten
          einer Verarbeitung aufgrund von Einwilligung oder Vertrag nach Art. 20
          Abs. 1 lit. a und b und soweit dies technisch machbar ist.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.6 </span>
          <span>Recht auf Widerspruch nach Art. 21 DSGVO</span>
        </h4>
        <p class="Body">
          Sie können grundsätzlich der Verarbeitung Ihrer personenbezogenen
          Daten widersprechen. Dies gilt insbesondere dann, wenn Ihr Interesse
          am Widerspruch das berechtigte Interesse des Verantwortlichen an der
          Verarbeitung überwiegt und wenn sich die Verarbeitung auf
          Direktwerbung und oder Profiling bezieht.{" "}
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.7 </span>
          <span>Recht auf „Entscheidung im Einzelfall“ nach Art. 22 DSGVO</span>
        </h4>
        <p class="Body">
          Sie haben grundsätzlich das Recht, nicht einer ausschließlich auf
          einer automatisierten Verarbeitung (einschließlich Profiling)
          beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
          rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
          beeinträchtigt. Auch dieses Recht findet allerdings Einschränkungen
          und Ergänzungen in Art. 22 Abs. 2 und 4 DSGVO.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.8 </span>
          <span>Weitere Rechte</span>
        </h4>
        <p class="Body">
          Die DSGVO beinhaltet umfassende Rechte der Unterrichtung von Dritten
          darüber, ob oder wie Sie Rechte nach Art. 16, 17, 18 DSGVO geltend
          gemacht haben. Dies allerdings nur insoweit dies auch möglich bzw. mit
          einem angemessenen Aufwand durchführbar ist.{" "}
        </p>
        <p class="Body">
          Wir möchten Sie an dieser Stelle erneut auf Ihr Recht des Widerrufs
          einer erteilten Einwilligung nach Art. 7 Abs. 3 DSGVO hinweisen. Die
          Rechtmäßigkeit der bis dahin durchgeführten Verarbeitung wird dadurch
          allerdings nicht berührt.
        </p>
        <p class="Body">
          Außerdem möchten wir Sie auch noch auf Ihre Rechte nach §§ 32 ff. BDSG
          hinweisen, die allerdings inhaltlich größtenteils deckungsgleich mit
          den soeben beschrieben Rechten sind.
        </p>
        <h4 class="DieterHeading4">
          <span class="number-prefix-p">4.1.9 </span>
          <span>Beschwerderecht nach Art. 77 DSGVO</span>
        </h4>
        <p class="Body">
          Sie haben auch das Recht sich bei einer Datenschutzaufsichtsbehörde zu
          beschweren, wenn Sie der Ansicht sind, dass eine Verarbeitung der Sie
          betreffenden personenbezogenen Daten gegen diese Verordnung verstößt.
        </p>
        <h2 class="DieterHeading2">
          <span class="number-prefix-p">5. </span>
          <span>
            Was ist, wenn morgen die DSGVO abgeschafft wird oder andere
            Veränderungen stattfinden?
          </span>
        </h2>
        <p class="Body">
          Der aktuelle Stand dieser Datenschutzerklärung ist 21.05.2024. Von
          Zeit zu Zeit ist es erforderlich, den Inhalt der Datenschutzerklärung
          anzupassen, um auf tatsächliche und rechtliche Veränderungen zu
          reagieren. Wir behalten uns daher vor, diese Datenschutzerklärung
          jederzeit zu ändern. Wir werden die geänderte Version an gleicher
          Stelle veröffentlichen und empfehlen Ihnen, die Datenschutzerklärung
          regelmäßig zu lesen.
        </p>
        <p class="DieterSmall">
          <span>Erstellt mit freundlicher Unterstützung von </span>
          <a href="https://www.dieter-datenschutz.de/">
            <span>Dieter macht den Datenschutz</span>
          </a>
        </p>
      </div>
    </div>
  );
}

export default Dsgvo;
