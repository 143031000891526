export const authenticate = (res, next) => {
    if(window !== undefined) {
        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('user', res.data.name);
    }
    next();
}

export const getToken = () => {
    if(window !== 'undefined') {
        if(sessionStorage.getItem('token')) {
            return sessionStorage.getItem('token');
        } else {
            return false;
        }
    }
}

export const getUser = () => {
    if (window !== 'undefined') {
        if (sessionStorage.getItem('user')){
            return sessionStorage.getItem('user');
        } else {
            return false;
        }
    }
}

export const logout = next => {
    if (window !== 'undefined') {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
    }
    next();
}