import React from 'react'
import {Link} from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div id="footer">
        <nav id="footernav" className="border split-nav">
        <div className="nav-brand">
            <p>&copy; 2024 Daytrash.de</p>
        </div>
            <div className="inline text-end">
                <Link to="impressum">Impressum</Link>
                <Link to="dsgvo">Datenschutz</Link>
            </div>
        </nav>
    </div>
  )
}

export default Footer
