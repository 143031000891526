import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import Electronics from './electronics/Electronics';
import Programming from './programming/Programming';
import Impressum from './impressum-dsgvo/Impressum';
import Dsgvo from './impressum-dsgvo/Dsgvo';
import Home from './Home';
import Login from './Login';
import './App.css';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <Router>
      <main>
      <Navigation />
      <Switch>
        <Route path="/" component={Home} exact/>
        <PrivateRoute path="/electronics" component={Electronics} exact/>
        <PrivateRoute path="/programming" component={Programming} exact/>
        <Route path="/login" component={Login} exact/>
        <Route path="/impressum" component={Impressum} exact/>
        <Route path="/dsgvo" component={Dsgvo} exact/>
        <Redirect to="/" />
      </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
